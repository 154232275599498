import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import { withTranslation } from 'react-i18next';

import { siteTypesEnum, beautifyUrl } from '../../../helpers';
import { fetchLeadGenFormAction } from '../../../store/commonDucks/leadGenForm';
import { localizePath } from '../../../helpers/locale';
import Auth from '../../../services/AuthService';
import Utils from '../../../utils';
import Link from '../../shared/Link/Link';

import './AgendaDaySelectors.scss';

export class AgendaDaySelectors extends Component {
  static propTypes = {
    mobile: PropTypes.bool,
    options: PropTypes.object,
    siteAgenda: PropTypes.object,
    siteDownloadableAgenda: PropTypes.object,
    history: PropTypes.object,
    fluid: PropTypes.bool,
  };

  downloadHandler = (e) => {
    e.preventDefault();

    const { pageConfig: { siteId } = {}, fetchLeadGenForm } = this.props;

    const token = Auth.getToken();
    const queryParams = {};

    if (token) {
      queryParams.token = token;
      queryParams.username = jwtDecode(token)['cognito:username'];
    }

    const agendaPdfLink = e.currentTarget.href;

    fetchLeadGenForm(
      {
        leadGenType: 'agenda',
        leadGenId: siteId,
        queryParams,
      },
      (response) => {
        if (response.statusCode === 200) {
          document.location.href = agendaPdfLink;
        }
      },
    );
  };

  componentDidUpdate() {
    this.redirectToAgendaPdfDownloadPage();
  }

  redirectToAgendaPdfDownloadPage() {
    const {
      pageConfig: { sitePath } = {},
      options: { data: { locales: { primary } = {} } = {} } = {},
      history: { location: { pathname = '' } = {} } = {},
      i18n: { language } = {},
      leadGenForm: { loaded, statusCode } = {},
    } = this.props;

    // we are checking pathname to prevent the unnecessary call of push method
    if (!pathname.includes('download')) {
      if (loaded && statusCode === 200) {
        // If agenda is gated (leadGenForm is loaded) we will show page with agenda leadGen form
        const path = localizePath(
          `/${sitePath}/agenda/download/`,
          language,
          primary,
        );

        document.location.href = beautifyUrl(
          `${window.location.origin}${path}`,
        );
      } else if (statusCode !== null && statusCode !== 200) {
        // If agenda is not gated (leadGenForm is not loaded) we will provide direct link for downloading a pdf file
        document.location.href = localizePath(
          `/${sitePath}/downloads/agenda`,
          language,
          primary,
        );
      }
    }
  }

  isLandingPageMode(deliveryMethod, format) {
    return deliveryMethod === 'LANDINGPAGE' || format === 'CUSTOM_URL';
  }

  isCustomMode(landingPageMode, format, fileName) {
    return !!(
      !landingPageMode &&
      ['CUSTOM', 'CUSTOM_PDF'].includes(format) &&
      fileName
    );
  }

  isAutoGenerated(landingPageMode, customMode, format, fileName) {
    return !!(!landingPageMode && !customMode && format !== 'NONE' && fileName);
  }

  isMobileBar(
    mobileMenu,
    customMode,
    landingPageMode,
    autoGenerated,
    directDownload,
  ) {
    return !mobileMenu &&
      (customMode || landingPageMode || autoGenerated || directDownload)
      ? 'mobile-bar'
      : '';
  }

  render() {
    const {
      options: { data: { eventId } = {} } = {},
      pageConfig: { sitePath, siteType } = {},
      i18n: { language } = {},
      siteAgenda: {
        data: { allDates = [], selectedDay, name, type } = {},
      } = {},
      siteDownloadableAgenda: {
        data: { deliveryMethod, fileName, format, landingPageUrl } = {},
      } = {},
      mobile = false,
      fluid,
      t,
    } = this.props;
    const { EVENT } = siteTypesEnum();
    const agendaType = eventId ? 'event' : 'course';

    const landingPageMode = this.isLandingPageMode(deliveryMethod, format);

    const customMode = this.isCustomMode(landingPageMode, format, fileName);

    const autoGenerated = this.isAutoGenerated(
      landingPageMode,
      customMode,
      format,
      fileName,
    );

    const directDownload = deliveryMethod === 'DIRECTDOWNLOAD';
    const mobileMenu = mobile ? 'mobile-menu' : '';
    const mobileBar = this.isMobileBar(
      mobileMenu,
      customMode,
      landingPageMode,
      autoGenerated,
      directDownload,
    );
    const downloadAgendaText = eventId
      ? t('event.agenda.download')
      : `download ${name}`;
    const downloadAgendaUrl = Auth.isLoggedIn()
      ? '/downloads/agenda'
      : '/agenda/download/';

    return (
      <div className={`c-agenda-day-selectors ${mobileMenu} ${mobileBar}`}>
        <div className={`container${fluid ? '-fluid' : ''}`}>
          <div className="row">
            <div className="col-xs-12">
              <ul className="agenda-days">
                {(customMode || autoGenerated) && (
                  <li className={`agenda-download ${agendaType}`}>
                    <Link
                      to={downloadAgendaUrl}
                      lang={language}
                      event={sitePath}
                      onClick={this.downloadHandler}
                    >
                      {t('event.agenda.download')}
                      <i className="fa fa-download" />
                    </Link>
                  </li>
                )}
                {landingPageMode && (
                  <li className={`agenda-download ${agendaType}`}>
                    <Link to={landingPageUrl}>
                      {downloadAgendaText}
                      <i className="fa fa-download" />
                    </Link>
                  </li>
                )}
                {allDates.map((item, index) => {
                  const isSelectedDay =
                    selectedDay === ~~item.dayNumber ? 'selected' : '';
                  const showDateLabel =
                    item.agendaDateViewType === 'DATE' &&
                    !!item.date &&
                    (EVENT === siteType || type === 'Scheduled');

                  return (
                    <li
                      key={index}
                      className={`agenda-day-selector ${isSelectedDay}`}
                    >
                      <Link to={`/agenda/${item.dayNumber}/`} event={sitePath}>
                        {showDateLabel
                          ? Utils.formattedTime(item.date, 'MMM D', language)
                          : item.name}
                        {mobile && showDateLabel && <span>{item.name}</span>}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    options: state.options,
    siteAgenda: state.siteAgenda,
    siteDownloadableAgenda: state.siteDownloadableAgenda,
    pageConfig: state.pageConfig,
    leadGenForm: state.leadGenForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLeadGenForm({ leadGenType, leadGenId, queryParams }, callback) {
      return dispatch(
        fetchLeadGenFormAction(
          { leadGenType, leadGenId, queryParams },
          callback,
        ),
      );
    },
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withCookies(withRouter(AgendaDaySelectors))),
);
